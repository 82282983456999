
import { store } from '@/store';
import authService from '@/services/AuthService';
import { organisationModule } from './OrganisationModule';
import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import { Profile } from '@/models/Profile';

@Module({
    namespaced: true,
    name: 'auth',
    dynamic: true,    
    store    
})
class AuthModule extends VuexModule {
    public profile: Profile | null = this.initializeProfile();

    public get isAuthenticated() : boolean {   
        if (this.profile && this.profile.token)
            return true;
        return false;
    }

    public initializeProfile(): Profile | null {
        const storedProfile = localStorage.getItem('profile');
        if (storedProfile) {
            return JSON.parse(storedProfile) as Profile
        }

        return null;
    }

    @Mutation 
    commitSetProfile(profile: Profile) {
        this.profile = profile;
        organisationModule.commitClearOrganisation();
        localStorage.setItem('profile', JSON.stringify(profile));
    }

    @Mutation 
    commitClearProfile() {
        this.profile = null;
        organisationModule.commitClearOrganisation();
        localStorage.removeItem("profile");
    }

    @Action
    public async login(payload: { username: string; password: string }): Promise<boolean> {
        this.commitClearProfile();
                
        const profile = await authService.login(payload.username, payload.password);
        if (profile) {
            this.commitSetProfile(profile);
            return true;
        }

        return false;
    }
    
    @Action
    public logout() {
        this.commitClearProfile();
    }
}

export const authModule = getModule(AuthModule);