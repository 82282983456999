import { Profile } from '@/models/Profile';
import { BaseService } from './BaseService';

class AuthService extends BaseService {

  public async login(username: string, password: string): Promise<Profile> {

    try
    {
      const response = await this.post('internal/authentication', {username: username, password: password});
      return response.data as Profile;
    }
    catch {
        return null as unknown as Profile;
    }
  }

}

const service: AuthService = new AuthService();
export default service;