import Vue from 'vue';
import App from './App.vue';
import router from './router';
import 'core-js';
import moment from 'moment';
import { Config } from './Configuration';
import i18n from '@/common/utils/i18n/i18n';
import vuetify from './plugins/vuetify'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import "@/common/assets/stylesheets/bootstrap.scss";
import '@mdi/font/css/materialdesignicons.css';

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

moment.locale('sv')

Vue.config.productionTip = false;

if (document.getElementById('app')) {

    Config.loadSettings().then(() => {
        new Vue({
            vuetify,
            router: router,
            i18n,            
            render: h => h(App)
        }).$mount('#app');
    });
}