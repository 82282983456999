import axios from 'axios';
import { authModule } from './store/modules/auth';

class Configuration {
    public Settings:
        {
            BaseUrl: ""
        };

    private readonly SettingsFile: string = process.env.VUE_APP_SETTINGSFILE;

    public async loadSettings(): Promise<any> {

        let response = await axios.get(this.SettingsFile);

        this.Settings = response.data;

        // TODO: move to BaseService?
        axios.defaults.headers.common['Pragma'] = 'no-cache';
        axios.defaults.headers.common['Cache-Control'] = 'no-cache';
        axios.defaults.headers.common['Accept'] = 'application/json';
        axios.defaults.withCredentials = true;
        axios.defaults.baseURL = this.Settings.BaseUrl;
        
        axios.interceptors.request.use(request => {
            if (authModule.isAuthenticated) {
                request.headers['Authorization'] = 'Bearer ' + authModule.profile?.token;
            }

            return request
        });

        axios.interceptors.response.use(response => {
            return response;
        }, error => {
            if (error.response.status === 401) {
              authModule.logout();
              //this.$router.push('/login')
            }            
            return Promise.reject(error)
        });
     
    }
};

export const Config = new Configuration();